import React from 'react';
import { lazy, Suspense } from 'react';
import { useRoutes, Outlet, Navigate } from 'react-router-dom';
import { wrapUseRoutes } from '@sentry/react';

import SimpleLayout from 'layouts/simple';
import CompactLayout from 'layouts/compact';

import { Spinner } from 'components/spinner';

import { paths } from 'routes/paths';
import { authRoutes } from './auth-routes';
import { errorRoutes } from './error-routes';
import { qrCodeRoutes } from './qrcode-routes';
import AuthenticatedRoute from './authenticated-route';

// ----------------------------------------------------------------------

// Menu
const MenusPage = lazy(() => import('@pages/menu/menus-page'));
const EditMenuPage = lazy(() => import('@pages/menu/edit-menu-page'));
const MenuSchedulingPage = lazy(() => import('@pages/menu/menu-scheduling-page'));
const CategoriesPage = lazy(() => import('@pages/menu/categories-page'));
const ItemsPage = lazy(() => import('@pages/menu/items-page'));
const EditItemPage = lazy(() => import('@pages/menu/edit-item-page'));
const BannerChangePage = lazy(() => import('@pages/menu/banner-change-page'));
const BannerCreationPage = lazy(() => import('@pages/menu/banner-creation-page'));
const BannerDetailsPage = lazy(() => import('@pages/menu/banner-details-page'));
// Club Profile
const ClubSettingsBoardPage = lazy(() => import('@pages/club-settings/board-page'));
const ClubContactsPage = lazy(() => import('@pages/club-settings/contacts-page'));
const AdminClubSettings = lazy(() => import('@pages/club-settings/admin-club-settings'));
const ClubPaymentPlan = lazy(() => import('@pages/club-settings/payment-plan'));
const BillingPage = lazy(() => import('@pages/club-settings/billing-page'));
const ClubQrCode = lazy(() => import('@pages/club-settings/club-qr-code'));
const AlertPage = lazy(() => import('@pages/club-settings/alert-page'));
// Club Creation
const ClubCreationPage = lazy(() => import('@pages/club-creation-page'));
const PaymentPage = lazy(() => import('@pages/payment-page'));
const ProceedToPaymentPage = lazy(() => import('@pages/proceed-to-payment-page'));
// Others
const AccountDeletionRequestPage = lazy(() => import('@pages/account-deletion-request-page'));

// ----------------------------------------------------------------------

const useSentryRoutes = wrapUseRoutes(useRoutes);

export default function Router() {
  return useSentryRoutes([
    {
      element: (
        <Suspense fallback={<Spinner />}>
          <Outlet />
        </Suspense>
      ),
      children: [
        {
          path: paths.menu.root,
          element: (
            <AuthenticatedRoute>
              <MenusPage />
            </AuthenticatedRoute>
          ),
          index: true,
        },
        {
          element: (
            <AuthenticatedRoute>
              <Outlet />
            </AuthenticatedRoute>
          ),
          children: [
            { path: paths.clubProfile.root, element: <ClubSettingsBoardPage /> },
            { path: paths.clubProfile.qrcode, element: <ClubQrCode /> },
            { path: paths.menu.editMenu, element: <EditMenuPage /> },
            { path: paths.menu.newMenu, element: <EditMenuPage /> },
            { path: paths.menu.scheduling, element: <MenuSchedulingPage /> },
            { path: paths.menu.categories, element: <CategoriesPage /> },
            { path: paths.menu.items, element: <ItemsPage /> },
            { path: paths.menu.editItem, element: <EditItemPage /> },
            { path: paths.menu.newItem, element: <EditItemPage /> },
            { path: paths.menu.banner, element: <BannerChangePage /> },
            { path: paths.menu.bannerNew, element: <BannerCreationPage /> },
            { path: paths.menu.bannerDetails, element: <BannerDetailsPage /> },
            { path: paths.menu.bannerDetailsNew, element: <BannerDetailsPage /> },
            { path: paths.clubProfile.contacts, element: <ClubContactsPage /> },
            { path: paths.clubProfile.settings, element: <AdminClubSettings /> },
            { path: paths.clubProfile.plan, element: <ClubPaymentPlan /> },
            { path: paths.clubProfile.billing, element: <BillingPage /> },
            { path: paths.clubProfile.alert, element: <AlertPage /> },
          ],
        },

        {
          path: paths.clubNew,
          element: (
            <AuthenticatedRoute>
              <CompactLayout>
                <ClubCreationPage />
              </CompactLayout>
            </AuthenticatedRoute>
          ),
        },
        {
          path: paths.clubPayment,
          element: (
            <AuthenticatedRoute>
              <SimpleLayout>
                <PaymentPage />
              </SimpleLayout>
            </AuthenticatedRoute>
          ),
        },
        {
          path: paths.proceedToPayment,
          element: (
            <AuthenticatedRoute>
              <CompactLayout>
                <ProceedToPaymentPage />
              </CompactLayout>
            </AuthenticatedRoute>
          ),
        },

        {
          path: paths.accountDeletion,
          element: (
            <CompactLayout>
              <AccountDeletionRequestPage />
            </CompactLayout>
          ),
        },

        ...authRoutes,

        ...errorRoutes,

        ...qrCodeRoutes,

        { path: '*', element: <Navigate to={paths.login} replace /> },
      ],
    },
  ]);
}
